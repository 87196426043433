export type Language = 'en' | 'no';

export const supportedLanguages: Language[] = ['en', 'no'];

const SELECTED_LANGUAGE_STORAGE_KEY = 'SELECTED_LANGUAGE';

const fallbackLanguage: Language = navigator.languages
  ? window.navigator.languages.indexOf('no') !== -1
    ? 'no'
    : 'en'
  : navigator.language && navigator.language.startsWith('en')
  ? 'en'
  : 'no';

export const getSelectedLanguage = (): Language => {
  try {
    const language = localStorage.getItem(SELECTED_LANGUAGE_STORAGE_KEY);
    return language ? (language as Language) : fallbackLanguage;
  } catch (error) {
    console.error(error);
    return fallbackLanguage;
  }
};

export const setSelectedLanguage = (language: Language) => {
  try {
    localStorage.setItem(SELECTED_LANGUAGE_STORAGE_KEY, language);
  } catch (error) {
    console.error(error);
  }
};
