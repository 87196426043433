import { css, cx } from 'emotion';
import React from 'react';
import { Flex } from './Flex';

export const PageContent: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Flex
      column
      className={cx(
        css`
          max-width: 700px;
          margin: 0 auto;
          padding: 0 16px;
        `,
        className
      )}
    >
      {children}
    </Flex>
  );
};
