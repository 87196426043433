import { RouteComponentProps } from '@reach/router';
import React, { FC, lazy, Suspense } from 'react';
import { TermsLayout } from './TermsLayout';
import { linkStyle } from '../../styles';
import { css } from 'emotion';

const Content = lazy(() => import('!babel-loader!mdx-loader!./md/DataProcessorAgreement.mdx'));

export const DataProcessorAgreement: FC<RouteComponentProps> = () => {
  return (
    <TermsLayout>
      <Suspense fallback={<div>Laster...</div>}>
        <div
          className={css`
            a {
              ${linkStyle}
            }
          `}
        >
          <Content />
        </div>
      </Suspense>
    </TermsLayout>
  );
};
