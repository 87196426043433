import { cx, css } from 'emotion';
import React from 'react';
import { Flex } from './Flex';
import { PageContent } from './PageContent';
import { Link } from '@reach/router';
import { colors } from '../utils/theme';

export const PageLayout: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Flex
      column
      className={cx(
        css`
          min-height: 100vh;
          padding-bottom: 100px;
        `,
        className
      )}
    >
      <PageContent
        className={css`
          flex-grow: 1;
          width: 100%;
        `}
      >
        {children}
        <div
          className={css`
            margin-top: 60px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            a {
              color: ${colors.primary};
              margin-right: 16px;
              margin-bottom: 8px;
              :hover {
                text-decoration: underline;
              }
            }
          `}
        >
          <Link to="/cookies">Cookies</Link>
          <Link to="/data-processor-agreement">Databehandleravtale</Link>
          <Link to="/terms-and-conditions">Brukervilkår</Link>
          <Link to="/privacy-policy">Personvernerklæring</Link>
        </div>
      </PageContent>
    </Flex>
  );
};
