import { RouteComponentProps } from '@reach/router';
import React, { FC, lazy, Suspense } from 'react';
import { TermsLayout } from './TermsLayout';

const Content = lazy(() => import('!babel-loader!mdx-loader!./md/Cookies.mdx'));

export const Cookies: FC<RouteComponentProps> = () => {
  return (
    <TermsLayout>
      <Suspense fallback={<div>Laster...</div>}>
        <Content />
      </Suspense>
    </TermsLayout>
  );
};
