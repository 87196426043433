import axios from 'axios';

export const apiRoot = (() => {
  if (window.location.hostname === 'localhost') {
    return process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3001';
  }
  if (window.location.hostname.endsWith('.test.iterate.no')) {
    return 'https://besokt-api.test.iterate.no';
  }
  return 'https://api.besokt.no';
})();

const besoktApiAddress = `${apiRoot}/api/v1`;

export const besoktApi = axios.create({
  withCredentials: true,
  baseURL: besoktApiAddress,
});
