import { Link, RouteComponentProps } from '@reach/router';
import { css } from 'emotion';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../components/Flex';
import { Logo } from '../../components/Logo';
import { PageLayout } from '../../components/PageLayout';
import { colors } from '../../utils/theme';
import { useBesoktLocation } from '../../utils/useBesoktLocation';

export const RegistrationSuccess: FC<RouteComponentProps & { id?: string }> = ({ id: locationId }) => {
  const { t } = useTranslation();
  const { data: location } = useBesoktLocation(locationId);

  return (
    <PageLayout>
      <Flex column>
        <Logo mode="TOGETHER" animated showLanguageSelector />
        <div
          className={css`
            height: 60px;
          `}
        />
        <h1>
          {t('You have')}{' '}
          <span
            className={css`
              color: ${colors.primary};
            `}
          >
            {t('visited')}
          </span>{' '}
          {location?.name ?? '-'}
        </h1>
        <h1>
          {t('Thank you for contributing')}
          <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6L7 0L14 4L20.5 0L27.5 5V13L14 25L0 13V6Z" fill="#BF5000" />
          </svg>
        </h1>
        <div
          className={css`
            height: 80px;
          `}
        />
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <button
            onClick={() => {
              window.history.back();
            }}
            className={css`
              background: ${colors.primary};
              border: none;
              color: white;
              font-size: 18px;
              padding: 16px 24px;
              width: 100%;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              clip-path: polygon(
                10px 0,
                calc(100% - 10px) 0,
                100% 10px,
                100% 50px,
                calc(100% - 10px) 60px,
                10px 60px,
                0 50px,
                0 10px
              );
            `}
          >
            {t('Register another')}
          </button>
        </div>
        <Link
          className={css`
            color: ${colors.primary};
            width: 100%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            margin-top: 64px;
            margin-bottom: 16px;
          `}
          to="/"
        >
          Besøkt.no
        </Link>
      </Flex>
    </PageLayout>
  );
};
