import { Redirect, Router } from '@reach/router';
import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ErrorFallbackWithWrapper } from './components/ErrorFallback';
import { Admin } from './pages/admin/Admin';
import { Besok } from './pages/besok/Besok';
import { RegistrationSuccess } from './pages/besok/RegistationSuccess';
import { Home } from './pages/home/Home';
import { Register } from './pages/register/Register';
import { Cookies } from './pages/terms/Cookies';
import { DataProcessorAgreement } from './pages/terms/DataProcessorAgreement';
import { PrivacyPolicy } from './pages/terms/PrivacyPolicy';
import { TermsAndConditions } from './pages/terms/TermsAndConditions';

const queryCache = new QueryCache();

export const App: FC = () => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ErrorBoundary FallbackComponent={ErrorFallbackWithWrapper}>
        <Router>
          <Home path="/" />
          <RegistrationSuccess path="/success/:id" />
          <Register path="/register" />
          <Admin path="/admin/:id/:token" />
          <Admin path="/admin/:id/:token/:name" />
          <Cookies path="/cookies" />
          <DataProcessorAgreement path="/data-processor-agreement" />
          <TermsAndConditions path="/terms-and-conditions" />
          <PrivacyPolicy path="/privacy-policy" />
          {/* This is to fix the QR code in the facebook post */}
          <Redirect from="/pe86" to="/" />
          <Besok path="/:id" />
        </Router>
      </ErrorBoundary>
    </ReactQueryCacheProvider>
  );
};
