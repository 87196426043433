import { Link, RouteComponentProps } from '@reach/router';
import { css } from 'emotion';
import React from 'react';
import slugify from 'slugify';
import { Logo } from '../../components/Logo';
import { PageLayout } from '../../components/PageLayout';
import { linkStyle, mainLinkStyle } from '../../styles';
import { apiRoot } from '../../utils/api';
import { colors } from '../../utils/theme';
import { Location, useBesoktLocation } from '../../utils/useBesoktLocation';

export const Admin: React.FC<RouteComponentProps<{ id?: string; token?: string }>> = ({
  id: locationId,
  token: adminToken,
}) => {
  const { isLoading, error, data: location } = useBesoktLocation(locationId);

  if (isLoading) {
    return (
      <PageLayout>
        <div
          className={css`
            height: 32px;
          `}
        />
        <h1>Admin</h1>
        <p>Laster...</p>
      </PageLayout>
    );
  }

  if (error) {
    throw error;
  }

  return (
    <PageLayout>
      <Logo />
      <div
        className={css`
          height: 32px;
        `}
      />
      {location && adminToken && <Content location={location} adminToken={adminToken} />}
    </PageLayout>
  );
};

const Content = ({ location, adminToken }: { location: Location; adminToken: string }) => {
  return (
    <div>
      <Link
        to="/"
        className={css`
          ${linkStyle};
          font-size: 20px;
        `}
      >
        besøkt
      </Link>
      <div
        className={css`
          height: 32px;
        `}
      />
      <h1>Administrer {location.name}</h1>
      <p>
        Takk for at du blir med på dugnaden for smittesporing. Du kan laste ned plakaten under. Heng denne opp godt
        synlig der du vil drive smittesporing. Det er lurt å printe ut flere plakater og henge opp.
      </p>
      <p>Om du trenger printe ut flere plakater, kan du alltid komme tilbake til denne lenken, ta godt vare på den.</p>
      <p>
        <a
          className={mainLinkStyle}
          href={`https://besokt.no/admin/${location.id}/${adminToken}/${slugify(location.name)}`}
        >
          https://besokt.no/admin/{location.id}/{adminToken}/{slugify(location.name)}
        </a>
      </p>
      <div
        className={css`
          height: 16px;
        `}
      />
      <a
        className={css`
          background: ${colors.primary};
          border: none;
          color: white;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          height: 60px;
          clip-path: polygon(
            10px 0,
            calc(100% - 10px) 0,
            100% 10px,
            100% 50px,
            calc(100% - 10px) 60px,
            10px 60px,
            0 50px,
            0 10px
          );

          @media screen and (min-width: 600px) {
            max-width: 240px;
          }
        `}
        href={`${apiRoot}/gfx/${location.id}/${adminToken}/poster.pdf`}
        rel="noopener noreferrer"
        target="_blank"
      >
        Last ned plakat (Norsk)
      </a>
      <a
        className={css`
          ${mainLinkStyle};
          display: block;
          margin-top: 16px;
        `}
        href={`${apiRoot}/gfx/${location.id}/${adminToken}/poster.pdf?language=en`}
        rel="noopener noreferrer"
        target="_blank"
      >
        Last ned plakat (Engelsk)
      </a>
      <div
        className={css`
          height: 16px;
        `}
      />
      <p>
        Om du har spørsmål eller andre henvendelser, ta kontakt med oss på{' '}
        <a
          className={css`
            color: ${colors.primary};
            font-weight: bold;
          `}
          href="mailto:hei@besokt.no"
        >
          hei@besokt.no
        </a>
        . Du kan også kontakt oss på den adressen for å få listen over besøkende. Merk: Vi vil bare sende listen til
        epostadressen eller telefonnummeret som du oppgav når du registrerte {location.name}.
      </p>
    </div>
  );
};
