import React, { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { mainLinkStyle, linkStyle } from '../styles';
import { css } from 'emotion';
import { PageLayout } from './PageLayout';
import { Logo } from './Logo';

export const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <h2>Det skjedde en feil</h2>
      <pre>{error?.message}</pre>
      <p>
        Om det gjentar seg, ta kontakt med{' '}
        <a className={linkStyle} href="mailto:hei@besokt.no">
          hei@besokt.no
        </a>
      </p>
      <button
        className={css`
          margin-top: 24px;
          ${mainLinkStyle}
        `}
        onClick={resetErrorBoundary}
      >
        Prøv igjen
      </button>
    </div>
  );
};

export const ErrorFallbackWithWrapper: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <PageLayout>
      <div>
        <Logo />
        <div
          className={css`
            height: 60px;
          `}
        />
        <ErrorFallback {...{ error, resetErrorBoundary }} />
      </div>
    </PageLayout>
  );
};
