import React from 'react';
import { css, cx } from 'emotion';

type FlexAlign =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'stretch'
  | 'space-around'
  | 'space-between'
  | 'space-evenly';

export type FlexProps = {
  as?: React.ElementType;
  inline?: boolean;
  column?: boolean;
  wrap?: boolean;
  grow?: boolean;
  reverse?: boolean;
  vertical?: FlexAlign;
  horizontal?: FlexAlign;
  className?: string;
  height?: string | number;
  width?: string | number;
  children?: React.ReactNode | React.ReactNode[];
  [props: string]: any;
};

export const Flex: React.FC<FlexProps> = React.forwardRef(
  (
    {
      as: Component = 'div',
      className,
      inline,
      column,
      wrap,
      grow,
      reverse,
      vertical,
      horizontal,
      height,
      width,
      ...rest
    }: FlexProps,
    ref: React.Ref<{}>
  ) => {
    return (
      <Component
        ref={ref}
        className={cx(
          'flex-builder',
          css`
            display: ${inline ? 'inline-flex' : 'flex'};
            flex-wrap: ${wrap === true ? 'wrap' : wrap};
            flex-direction: ${column ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row'};
            align-items: ${column ? horizontal : vertical};
            justify-content: ${column ? vertical : horizontal};
          `,
          height
            ? css`
                height: ${typeof height === 'number' ? `${height}px` : height};
              `
            : null,
          width
            ? css`
                width: ${typeof width === 'number' ? `${width}px` : width};
              `
            : null,
          grow &&
            css`
              flex-grow: 1;
            `,
          className
        )}
        {...rest}
      />
    );
  }
);
