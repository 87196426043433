import { Link } from '@reach/router';
import { css } from 'emotion';
import React, { FC } from 'react';
import { Logo } from '../../components/Logo';
import { PageLayout } from '../../components/PageLayout';
import { colors } from '../../utils/theme';

export const TermsLayout: FC = ({ children }) => {
  return (
    <PageLayout>
      <Logo />
      <div
        className={css`
          height: 60px;
        `}
      />
      <Link to="/">
        <h1
          className={css`
            font-size: 48px;
            color: ${colors.primary};

            @media screen and (min-width: 600px) {
              font-size: 78px;
            }
          `}
        >
          besøkt
        </h1>
      </Link>
      <div
        className={css`
          height: 40px;
        `}
      />
      <div>{children}</div>
    </PageLayout>
  );
};
