import { Link, RouteComponentProps } from '@reach/router';
import { css } from 'emotion';
import React, { FC } from 'react';
import { Logo } from '../../components/Logo';
import { PageLayout } from '../../components/PageLayout';
import { colors } from '../../utils/theme';
import andreas from './assets/andreas.png';
import henrik from './assets/henrik.png';
import martine from './assets/martine.png';
import neno from './assets/neno.png';
import rune from './assets/rune.png';
import sindre from './assets/sindre.png';
import benedicte from './assets/benedicte.png';
import { linkStyle } from '../../styles';
import { useTranslation } from 'react-i18next';

export const Home: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Logo showLanguageSelector />
      <div
        className={css`
          margin-top: 60px;
        `}
      >
        <h1
          className={css`
            font-size: 48px;
            color: ${colors.primary};

            @media screen and (min-width: 600px) {
              font-size: 78px;
            }
          `}
        >
          besøkt
        </h1>
        <h2
          className={css`
            font-size: 30px;

            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          `}
        >
          {t('Secure and free contact tracing')}
        </h2>
        <div
          className={css`
            div {
              display: flex;
              margin: 32px 0 32px 0;

              &:nth-child(even) {
                flex-direction: row-reverse;

                p {
                  margin-left: 0;
                  margin-right: 16px;
                }
              }

              p {
                margin-left: 16px;
                margin-top: calc(48px - 20px);

                @media screen and (min-width: 600px) {
                  margin-top: 32px;
                }
              }
            }
          `}
        >
          <div>
            <StepBox step={1} />
            <p>{t('step one')}</p>
          </div>
          <div>
            <StepBox step={2} />
            <p>{t('step two')}</p>
          </div>
          <div>
            <StepBox step={3} />
            <p>{t('step three')}</p>
          </div>
        </div>
        <Link
          to="/register"
          className={css`
            background: ${colors.primary};
            border: none;
            color: white;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            height: 60px;
            clip-path: polygon(
              10px 0,
              calc(100% - 10px) 0,
              100% 10px,
              100% 50px,
              calc(100% - 10px) 60px,
              10px 60px,
              0 50px,
              0 10px
            );

            @media screen and (min-width: 600px) {
              max-width: 240px;
            }
          `}
        >
          {t('Register your location')}
        </Link>
        <a
          href="#hvaerenlokasjon"
          className={css`
            ${linkStyle};
            display: block;
            margin-top: 8px;
          `}
        >
          {t('What is a location - Q')}
        </a>
        <div
          className={css`
            margin-top: 64px;
          `}
        >
          <p
            className={css`
              margin: 24px 0;
            `}
          >
            {t('besokt is developed by 1')}
            <a
              className={css`
                color: ${colors.primary};
                font-weight: bold;
              `}
              href="https://iterate.no"
            >
              Iterate
            </a>
            {t('besokt is developed by 2')}
          </p>

          <div>
            <h2
              className={css`
                margin-top: 64px;
              `}
            >
              {t('How does it work')}
            </h2>
            <p>{t('How does it work p-1')}</p>
            <p>{t('How does it work p-2')}</p>
            <p>{t('How does it work p-3')}</p>
          </div>
          <div>
            <h2
              className={css`
                margin-top: 64px;
              `}
            >
              {t('Frequently Asked Questions')}
            </h2>
            <h3 id="hvaerenlokasjon">{t('What is a location - Q')}</h3>
            <p>{t('What is a location - A')}</p>
            <h3>{t('Can i register multiple locations - Q')}</h3>
            <p>{t('Can i register multiple locations - A')}</p>
            <h3>{t('What if I need the list over visitors - Q')}</h3>
            <p>
              {t('What if I need the list over visitors - A-1')}
              <a
                className={css`
                  color: ${colors.primary};
                  font-weight: bold;
                `}
                href="mailto:hei@besokt.no"
              >
                hei@besokt.no
              </a>{' '}
              {t('What if I need the list over visitors - A-2')}
            </p>
            <h3>{t('Why do you store the data 14 days')}</h3>
            <p>{t('Why do you store the data 14 days - A-1')}</p>
            <p>{t('Why do you store the data 14 days - A-2')}</p>
            <h3>{t('Does it cost anything - Q')}</h3>
            <p>{t('Does it cost anything - A')}</p>
            <h3>{t('Is it secure - Q')}</h3>
            <p>{t('Is it secure - A-1')}</p>
            <p>{t('Is it secure - A-2')}</p>
            <h3>{t('What about GDPR - Q')}</h3>
            <p>
              {t('What about GDPR - A-1')}
              <Link className={linkStyle} to="/data-processor-agreement">
                {t('data processor agreement')}
              </Link>{' '}
              {t('What about GDPR - A-2')}
              <Link className={linkStyle} to="privacy-policy">
                {t('privacy policy')}
              </Link>
              .
            </p>

            <h2
              className={css`
                margin-top: 64px;
              `}
            >
              {t('Who are we - Q')}
            </h2>
            <p>{t('Who are we - A')}</p>
            <div
              className={css`
                margin-top: 24px;
                text-align: center;
                display: flex;
                flex-direction: column;

                div {
                  display: flex;
                  align-items: center;

                  img {
                    grid-row: 1;
                    width: 150px;
                    object-fit: cover;
                  }
                  div {
                    font-size: 20px;
                    margin: 0 16px;
                    grid-row: 2;
                  }

                  &:nth-child(odd) {
                    flex-direction: row-reverse;
                  }
                }
              `}
            >
              <div>
                <img alt="Andreas" src={andreas} />
                <div>Andreas Glasø Skifjeld</div>
              </div>
              <div>
                <img alt="Sindre" src={sindre} />
                <div>Sindre Ilebekk Johansen</div>
              </div>
              <div>
                <img alt="Henrik" src={henrik} />
                <div>Henrik Glasø Skifjeld</div>
              </div>
              <div>
                <img alt="Martine" src={martine} />
                <div>Martine Bongard</div>
              </div>
              <div>
                <img alt="Neno" src={neno} />
                <div>Neno Mindjek</div>
              </div>
              <div>
                <img alt="Rune" src={rune} />
                <div>Rune Larsen</div>
              </div>
              <div>
                <img alt="Benedicte" src={benedicte} />
                <div>Benedicte Varvik</div>
              </div>
            </div>

            <h2
              className={css`
                margin-top: 64px;
              `}
            >
              {t('Contact')}
            </h2>
            <p>
              {t('You can contact us at')}
              <a
                className={css`
                  color: ${colors.primary};
                  font-weight: bold;
                `}
                href="mailto:hei@besokt.no"
              >
                hei@besokt.no
              </a>
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const StepBox: FC<{ step: number }> = ({ step }) => {
  return (
    <div
      className={css`
        font-family: Manifont Grotesk;
        background-color: #ffe7df;
        font-size: 48px;
        color: ${colors.primary};
        min-width: 50px;
        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        clip-path: polygon(10px 0, 40px 0, 50px 10px, 50px 70px, 40px 80px, 10px 80px, 0 70px, 0 10px);
        span {
          margin-bottom: -3px;
        }

        @media screen and (min-width: 600px) {
          font-size: 78px;
          min-width: 65px;
          min-height: 100px;
          clip-path: polygon(10px 0, 55px 0, 65px 10px, 65px 90px, 55px 100px, 10px 100px, 0 90px, 0 10px);

          span {
            margin-bottom: -16px;
          }
        }
      `}
    >
      <span>{step}</span>
    </div>
  );
};
