import { css, cx } from 'emotion';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../i18n/i18n';
import { Language } from '../i18n/languageStorage';

type Mode = 'SEPARATED' | 'TOGETHER';

const flipMode = (mode: Mode): Mode => (mode === 'SEPARATED' ? 'TOGETHER' : 'SEPARATED');

export const Logo: FC<{ mode?: Mode; animated?: boolean; showLanguageSelector?: boolean }> = ({
  mode = 'SEPARATED',
  animated = false,
  showLanguageSelector = false,
}) => {
  const { i18n } = useTranslation();

  const [actualMode, setActualMode] = React.useState(animated ? flipMode(mode) : mode);

  React.useEffect(() => {
    if (animated) {
      setActualMode(mode);
    }
  }, [animated, mode]);

  const onLanguageChange = React.useCallback((language: Language) => {
    setLanguage(language);
  }, []);

  return (
    <div
      className={css`
        height: 22px;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      `}
    >
      <svg
        height="10"
        width="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx(
          css`
            position: absolute;
            transition: left 1s ease-in-out, transform 1s ease-in-out;
            path {
              transition: fill 1s ease-in-out;
            }
          `,
          actualMode === 'SEPARATED'
            ? css`
                left: 0;
              `
            : css`
                left: 50%;
                transform: translateX(-50%);

                path {
                  fill: #bf5000;
                }
              `
        )}
      >
        <path
          d="M4.98024 0L1.46245 1.46825L0 5L1.46245 8.53175L4.98024 10L8.53755 8.53175L10 5L8.53755 1.46825L4.98024 0Z"
          fill="#000000"
        />
      </svg>

      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx(
          css`
            path {
              transition: fill 1s ease-in-out;
            }
          `,
          actualMode === 'SEPARATED'
            ? css`
                path {
                  fill: #000000;
                }
              `
            : css`
                path {
                  fill: #040032;
                }
              `
        )}
      >
        <path
          d="M11 0L2.83668 3.22091L0 11.0144L2.83668 18.7791L11 22L19.1633 18.7791L22 11.0144L19.1633 3.22091L11 0ZM11 19.6418L5.16905 17.1686L3.2149 10.9856L5.29513 4.83137L11 2.35817L16.7049 4.83137L18.7851 10.9856L16.7049 17.1686L11 19.6418Z"
          fill={actualMode === 'SEPARATED' ? '#000000' : '#040032'}
        />
      </svg>

      {showLanguageSelector && (
        <div
          className={css`
            position: absolute;
            right: 0;

            button {
              color: #bf5000;
            }
          `}
        >
          <button
            className={cx(
              i18n.language === 'no' &&
                css`
                  text-decoration: underline;
                `
            )}
            onClick={() => onLanguageChange('no')}
          >
            No
          </button>
          &nbsp;|&nbsp;
          <button
            className={cx(
              i18n.language === 'en' &&
                css`
                  text-decoration: underline;
                `
            )}
            onClick={() => onLanguageChange('en')}
          >
            En
          </button>
        </div>
      )}
    </div>
  );
};
