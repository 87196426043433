import { RouteComponentProps, useNavigate } from '@reach/router';
import React, { FC } from 'react';
import slugify from 'slugify';
import { Input } from '../../components/Input';
import { PageLayout } from '../../components/PageLayout';
import { css } from 'emotion';
import { besoktApi } from '../../utils/api';
import { colors } from '../../utils/theme';
import { Logo } from '../../components/Logo';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

export const Register: FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const { t } = useTranslation();

  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [city, setCity] = React.useState('');
  const [ownerName, setOwnerName] = React.useState('');
  const [ownerPhone, setOwnerPhone] = React.useState('');
  const [ownerEmail, setOwnerEmail] = React.useState('');
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  const nameError = name.length < 2 ? t('error location') : undefined;
  const addressError = address.length < 4 ? t('error address') : undefined;
  const postalCodeError = postalCode.length < 4 ? t('error postal code') : undefined;
  const cityError = city.length < 2 ? t('error city') : undefined;
  const ownerNameError = ownerName.length < 4 ? t('error full name') : undefined;
  const ownerPhoneError = ownerPhone.length < 4 ? t('error phone number') : undefined;
  const ownerEmailError = ownerEmail.length < 4 ? t('error email') : undefined;

  const hasErrors =
    nameError || addressError || postalCodeError || cityError || ownerNameError || ownerPhoneError || ownerEmailError;

  const onSubmit = React.useCallback(async () => {
    setSubmitAttempted(true);

    if (hasErrors) {
      return;
    }

    try {
      const response = await besoktApi.post('location', {
        name,
        address,
        postalCode,
        city,
        ownerName,
        ownerPhone,
        ownerEmail,
      });

      navigate(`/admin/${response.data.id}/${response.data.adminToken}/${slugify(response.data.name)}`);
    } catch (error) {
      handleError(error);
    }
  }, [name, address, postalCode, city, ownerName, ownerPhone, ownerEmail, hasErrors, navigate, handleError]);

  return (
    <PageLayout>
      <Logo showLanguageSelector />
      <h1
        className={css`
          margin-top: 60px;
          margin-bottom: 32px;
        `}
      >
        {t('Register a new location')}
      </h1>
      <form>
        <h2>{t('Location information')}</h2>
        <Input
          type="text"
          label={t('Location name')}
          autoComplete="organization"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={submitAttempted && nameError ? nameError : undefined}
        />
        <Input
          type="text"
          label={t('Address')}
          autoComplete="street-address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          error={submitAttempted && addressError ? addressError : undefined}
        />
        <div
          className={css`
            display: flex;

            @media screen and (max-width: 600px) {
              flex-direction: column;
            }
          `}
        >
          <Input
            type="tel"
            label={t('Postal code')}
            autoComplete="postal-code"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            error={submitAttempted && postalCodeError ? postalCodeError : undefined}
          />
          <div
            className={css`
              width: 16px;
            `}
          />
          <Input
            type="text"
            label={t('City')}
            autoComplete="address-level2"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            error={submitAttempted && cityError ? cityError : undefined}
          />
        </div>
        <div
          className={css`
            height: 24px;
          `}
        />
        <h2>{t('Information about you')}</h2>
        <p>{t('We need some information about you')}</p>
        <Input
          type="text"
          label={t('Name')}
          autoComplete="name"
          value={ownerName}
          onChange={(e) => setOwnerName(e.target.value)}
          error={submitAttempted && ownerNameError ? ownerNameError : undefined}
        />
        <Input
          type="tel"
          label={t('Phone number')}
          autoComplete="tel"
          value={ownerPhone}
          onChange={(e) => setOwnerPhone(e.target.value)}
          error={submitAttempted && ownerPhoneError ? ownerPhoneError : undefined}
        />
        <Input
          type="email"
          label={t('Email')}
          autoComplete="email"
          value={ownerEmail}
          onChange={(e) => setOwnerEmail(e.target.value)}
          error={submitAttempted && ownerEmailError ? ownerEmailError : undefined}
        />
        <div
          className={css`
            height: 24px;
          `}
        />
        <p>
          {t('By registering a location')}
          <a
            className={css`
              color: ${colors.primary};
              font-weight: bold;
            `}
            href="/terms-and-conditions"
            target="_blank"
          >
            {t('terms')}
          </a>
        </p>
        <button
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            onSubmit();
          }}
          className={css`
            background: ${colors.primary};
            border: none;
            color: white;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            height: 60px;
            width: 100%;
            clip-path: polygon(
              10px 0,
              calc(100% - 10px) 0,
              100% 10px,
              100% 50px,
              calc(100% - 10px) 60px,
              10px 60px,
              0 50px,
              0 10px
            );

            @media screen and (min-width: 600px) {
              max-width: 240px;
            }
          `}
        >
          {t('Register location')}
        </button>
        <div
          className={css`
            height: 64px;
          `}
        />
      </form>
    </PageLayout>
  );
};
