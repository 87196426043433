import { css } from 'emotion';
import { colors } from './utils/theme';

export const linkStyle = css`
  color: ${colors.primary};
`;

export const mainLinkStyle = css`
  ${linkStyle};
  font-weight: bold;
`;
