import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { besoktApi } from './api';

export type Location = { id: string; name: string };

export const useBesoktLocation = (locationId?: string) => {
  return useQuery<Location, AxiosError>('locationFetch', () =>
    besoktApi(`/location/${locationId?.toLocaleLowerCase()}`).then((res) => res.data)
  );
};
