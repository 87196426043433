import React, { FC } from 'react';
import { cx, css } from 'emotion';
import { Flex } from './Flex';

export const Input: FC<
  {
    label: string;
    error?: string;
  } & React.ComponentPropsWithRef<'input'>
> = ({ label, error, className, ...props }) => {
  return (
    <label
      className={css`
        display: block;
        margin-bottom: 16px;
        font-size: 20px;
        flex-grow: 1;
      `}
    >
      <Flex column>
        <div
          className={css`
            margin-bottom: 8px;
            font-weight: 500;
          `}
        >
          {label}
        </div>
        <input
          className={cx(
            css`
              border: none;
              font-size: 24px;
              width: 100%;
              padding: 11px 16px;
              height: 50px;
              clip-path: polygon(
                10px 0,
                calc(100% - 10px) 0,
                100% 10px,
                100% 40px,
                calc(100% - 10px) 50px,
                10px 50px,
                0 40px,
                0 10px
              );
            `,
            error &&
              css`
                border: 1px solid red;
              `,
            className
          )}
          {...props}
        />
        {error && (
          <div
            className={css`
              font-size: 14px;
              margin-top: 4px;
              margin-left: 8px;
              font-weight: 500;
            `}
          >
            {error}
          </div>
        )}
      </Flex>
    </label>
  );
};
