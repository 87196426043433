import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { css } from 'emotion';
import React, { FC } from 'react';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ErrorFallback } from '../../components/ErrorFallback';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Logo } from '../../components/Logo';
import { PageLayout } from '../../components/PageLayout';
import { besoktApi } from '../../utils/api';
import { useBesoktLocation } from '../../utils/useBesoktLocation';
import { colors } from '../../utils/theme';

export const Besok: FC<RouteComponentProps<{ id?: string }>> = ({ id: locationId }) => {
  const { t } = useTranslation();
  const { isLoading, error, data: location } = useBesoktLocation(locationId);

  if (isLoading) {
    return <Wrapper>{t('Loading')}...</Wrapper>;
  }

  if (error) {
    return (
      <Wrapper>
        {t('Could not find')} {locationId}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h2
        className={css`
          font-size: 48px;
          margin-bottom: 48px;
        `}
      >
        {location?.name ?? '-'}
      </h2>

      <RegisterForm locationId={locationId!} />
    </Wrapper>
  );
};

const Wrapper: FC = ({ children }) => {
  return (
    <PageLayout>
      <Flex column horizontal="stretch">
        <Logo showLanguageSelector />
        <div
          className={css`
            height: 60px;
          `}
        />
      </Flex>
      <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
    </PageLayout>
  );
};

const getFromLocalStorage = (key: 'name' | 'phone'): string => {
  try {
    return localStorage.getItem(key) ?? '';
  } catch (_) {
    return '';
  }
};

const storeToLocalStorage = (key: 'name' | 'phone', value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (_) {}
};

const RegisterForm = ({ locationId }: { locationId: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fullName, setFullName] = React.useState(() => getFromLocalStorage('name'));
  const [phone, setPhone] = React.useState(() => getFromLocalStorage('phone'));
  const [rememberMe, setRememberMe] = React.useState(true);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const handleError = useErrorHandler();

  const nameError = fullName.length < 4 ? t('Enter your full name') : undefined;
  const phoneError = phone.length < 4 ? t('Enter a valid phone number') : undefined;

  const hasErrors = nameError || phoneError;

  const onSubmit = React.useCallback(async () => {
    setSubmitAttempted(true);

    if (hasErrors) {
      return;
    }

    try {
      await besoktApi.post(`location/${locationId.toLocaleLowerCase()}/visit`, { fullName, phone });

      storeToLocalStorage('name', rememberMe ? fullName : '');
      storeToLocalStorage('phone', rememberMe ? phone : '');

      if (!rememberMe) {
        setFullName('');
        setPhone('');
      }

      navigate(`/success/${locationId}`);
    } catch (err) {
      handleError(err);
    }
  }, [locationId, fullName, phone, rememberMe, navigate, hasErrors, handleError]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <Input
        type="text"
        label={t('Name')}
        autoComplete="name"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        error={submitAttempted && nameError ? nameError : undefined}
      />
      <Input
        type="tel"
        label={t('Phone')}
        autoComplete="tel"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        error={submitAttempted && phoneError ? phoneError : undefined}
      />

      <label>
        <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe((old) => !old)} />
        <span
          className={css`
            margin-left: 4px;
          `}
        >
          {t('Remember me')}
        </span>
      </label>

      <RegisterButton onSubmit={onSubmit} />
      <div
        className={css`
          margin-top: 30px;
          font-size: 20px;
        `}
      >
        <span
          className={css`
            position: relative;
            top: 3px;
          `}
        >
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 0L2.83668 3.22091L0 11.0144L2.83668 18.7791L11 22L19.1633 18.7791L22 11.0144L19.1633 3.22091L11 0ZM11 19.6418L5.16905 17.1686L3.2149 10.9856L5.29513 4.83137L11 2.35817L16.7049 4.83137L18.7851 10.9856L16.7049 17.1686L11 19.6418Z"
              fill="#BF5000"
            />
            <path
              d="M11.501 8.337L12.109 7.653V6.684L11.482 6H10.608L10 6.684V7.653L10.627 8.337H11.501ZM11.919 16.851V9.971H10.209V16.851H11.919Z"
              fill="#BF5000"
            />
          </svg>
        </span>{' '}
        <span>{t('By registering you are accepting terms')}:</span>
      </div>
      <div
        className={css`
          margin-top: 28px;
          font-size: 20px;
          line-height: 22px;
        `}
      >
        <p
          className={css`
            font-weight: bold;
            margin-bottom: 0px;
          `}
          id="vilkaar"
        >
          {t('Our terms')}
        </p>
        <p
          className={css`
            margin-top: 0px;
          `}
        >
          {t('register terms')}
        </p>
        <Link
          className={css`
            color: ${colors.primary};
          `}
          to="/terms-and-conditions"
        >
          {t('Complete Terms')}
        </Link>
      </div>
    </form>
  );
};

const RegisterButton = ({ onSubmit }: { onSubmit: () => Promise<void> }) => {
  const { t } = useTranslation();
  return (
    <button
      type="submit"
      onClick={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      className={css`
        background: ${colors.primary};
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: white;
        font-size: 18px;
        font-weight: bold;
        margin-top: 32px;
        padding: 16px;
        width: 100%;
        height: 60px;
        clip-path: polygon(
          10px 0,
          calc(100% - 10px) 0,
          100% 10px,
          100% 50px,
          calc(100% - 10px) 60px,
          10px 60px,
          0 50px,
          0 10px
        );
      `}
    >
      {t('Register visit')}
    </button>
  );
};
